// Import our CSS
import "@/scss/swiper.scss";

import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper/modules";

window.Swiper = Swiper;

let sliders = document.querySelectorAll(".swiper-slider");
sliders.forEach((slider) => {
    let settings = {};
    let modules = { modules: [] };

    settings["modules"] = [];
    if (slider.dataset.modulenav) {
        modules["modules"].push(Navigation);
    }

    if (slider.dataset.modulepagination) {
        modules["modules"].push(Pagination);
    }

    if (slider.dataset.moduleautoplay) {
        modules["modules"].push(Autoplay);
    }

    if (slider.dataset.settings) {
        settings = JSON.parse(slider.dataset.settings);
    }

    settings = merge(modules, settings);
    let swiper = new Swiper(slider, settings);
});

function merge(modules, settings) {
    for (var key in settings) {
        if (settings.hasOwnProperty(key)) modules[key] = settings[key];
    }
    return modules;
}

/*let thumbSliderproduct = document.querySelector(".thumbsliderproduct");
if (thumbSliderproduct) {
    var thumbslider = new Swiper(".thumbsliderproduct", {
        spaceBetween: 10,
        slidesPerView: 14,
        freeMode: true,
        watchSlidesProgress: true,
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });

    var productslider = new Swiper(".productslider", {
        loop: true,
        spaceBetween: 32,
        slidesPerView: 1.5,
        autoHeight: true,
        direction: "vertical",
        // navigation: {
        //     nextEl: ".swiper-button-next-product",
        //     prevEl: ".swiper-button-prev-product",
        // },
        thumbs: {
            swiper: thumbSliderproduct,
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });
}*/

let herothumbslider = document.querySelector(".herothumbslider");
let herosliderElement = document.querySelector(".heroslider");

if (herothumbslider && herosliderElement) {
    const slideTime = parseInt(herosliderElement.getAttribute("data-slide-time")) || 8000;
    const autoPlay = herosliderElement.getAttribute("data-autoplay") === "1";
    const randomSlide = herosliderElement.getAttribute("data-random-slide") === "1";

    function shuffleSyncedSlides(heroSlider, thumbSlider) {
        const heroWrapper = heroSlider.el.querySelector('.swiper-wrapper');
        const thumbWrapper = thumbSlider.el.querySelector('.swiper-wrapper');
        const heroSlides = Array.from(heroWrapper.children);
        const thumbSlides = Array.from(thumbWrapper.children);

        const indices = heroSlides.map((_, index) => index);

        for (let i = indices.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [indices[i], indices[j]] = [indices[j], indices[i]];
        }

        const newHeroSlides = indices.map(i => heroSlides[i]);
        const newThumbSlides = indices.map(i => thumbSlides[i]);

        while (heroWrapper.firstChild) heroWrapper.removeChild(heroWrapper.firstChild);
        while (thumbWrapper.firstChild) thumbWrapper.removeChild(thumbWrapper.firstChild);

        newHeroSlides.forEach(slide => heroWrapper.appendChild(slide));
        newThumbSlides.forEach(slide => thumbWrapper.appendChild(slide));

        heroSlider.update();
        thumbSlider.update();
    }

    var thumbslider = new Swiper(".herothumbslider", {
        spaceBetween: 10,
        loop: true,
        slidesPerView: 1.5,
        watchSlidesProgress: true,
        progressBar: true,
        autoplay: autoPlay ? {
            delay: slideTime,
        } : false,
        breakpoints: {
            540: {
                slidesPerView: 2.5,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });

    var heroslider = new Swiper(".heroslider", {
        loop: true,
        spaceBetween: 0,
        slidesPerView: 1,
        autoHeight: true,
        autoplay: autoPlay ? {
            delay: slideTime,
        } : false,
        on: {
            init: function() {
                if (randomSlide) {
                    shuffleSyncedSlides(this, thumbslider);
                    // Re-establish thumbs relationship after shuffling
                    this.thumbs.swiper = thumbslider;
                    this.thumbs.init();
                    this.thumbs.update();
                }
            },
            autoplayTimeLeft(s, time, progress) {
                const currentSlide = thumbslider
                    ? thumbslider.el.querySelector(".swiper-slide-thumb-active")
                    : null;
                if (currentSlide) {
                    const progressBar =
                        currentSlide.querySelector(".progressBar");
                    if (progressBar) {
                        const widthPercentage = parseFloat(progress) * 100;
                        progressBar.style.width = widthPercentage + "%";
                    }
                }
            },
            slideChange(s) {
                for (var i = 0; i < thumbslider.slides.length; i++) {
                    if (
                        !thumbslider.slides[i].classList.contains(
                            "swiper-slide-thumb-active"
                        )
                    ) {
                        thumbslider.slides[i].querySelector(
                            ".progressBar"
                        ).style.width = "0%";
                    }
                }
            },
        },
        navigation: {
            nextEl: ".swiper-button-next-hero",
            prevEl: ".swiper-button-prev-hero",
        },
        thumbs: {
            swiper: thumbslider,
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });
}
